<template>
  <div class="body">
    <el-row :gutter="20">
      <el-upload
        :action= "url"
        :data="{testId:this.$route.query.id}"
        class="upload-btn"
        accept=".xls, .xlsx"
        :on-success="handleAvatarSuccess"
        :show-file-list="false"
      >
        <!-- <el-button size="small" type="primary" >导入excel</el-button> -->
      </el-upload>
      <el-button class="poster" type="warning" @click="poster">返回</el-button>
      <el-card>
        <div class="tip">单选题</div>
        <template>
          <el-radio-group v-model="radioTwo">
          </el-radio-group>
        </template>
        <div class="content" v-if="radioTwo==1">
          <el-row :gutter="100">
            <div v-for="(item,index) in singleChoice" :key="item.id">
              <el-form
                :model="item"
                label-position="right"
                label-width="120px"
                ref="classFrom" >
                <el-col :span="20">
                  <el-form-item label="设置分数" required prop="">
                    <el-input style="width: 600px" v-model="item.score" :disabled="isDisabled"></el-input>
                  </el-form-item>
                  <el-form-item :label="'题目'+(index+ 1)" required prop="">
                    <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"></el-input>
                  </el-form-item>
                  <el-form-item :label="'选项'+answeiLable(childIndex)" required prop="questionsOptions" v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                    <el-input :disabled="isDisabled" style="width: 465px;" v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption"/>
                    <!-- <el-radio v-model="questionsOptions.correctAnswer" label="1">正确</el-radio> -->
                    <el-radio v-model="questionsOptions.correctAnswer" :label='1'>正确</el-radio>
                    <el-button @click="deLete(item, index ,childIndex)">删除</el-button>
                  </el-form-item>
                  <el-button type="text" @click="addinputSingle(item,index)">添加选项</el-button>
                  <el-button type="text" @click="addSingleChoice()">添加试题</el-button>
                  <el-button type="text" @click="delSingleChoice(index)">删除试题</el-button>
                  <el-divider></el-divider>
                </el-col>
              </el-form>
            </div>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <div class="tip">多选题</div>
        <template>
          <el-radio-group v-model="radioTwo">
          </el-radio-group>
        </template>
        <div class="content" v-if="radioTwo==1">
          <el-row :gutter="100">
            <div v-for="(item,index) in singleChoide" :key="item.id">
              <el-form
                :model="item"
                label-position="right"
                label-width="120px"
                ref="classFrom" >
                <el-col :span="20">
                  <el-form-item label="设置分数" required prop="">
                    <el-input style="width: 600px" v-model="item.score" :disabled="isDisabled"></el-input>
                  </el-form-item>
                  <el-form-item :label="'题目'+(index+ 1)" required prop="">
                    <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"></el-input>
                  </el-form-item>
                  <el-form-item :label="'选项'+answeiLable(childIndex)" required prop="" v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                    <el-input style="width: 465px;" v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption" :disabled="isDisabled"/>
                    <!-- <el-radio v-model="questionsOptions.correctAnswer" :true-label="1" :false-label="0">正确</el-radio> -->
                    <el-checkbox v-model="questionsOptions.correctAnswer" :true-label="1" :false-label="0">正确</el-checkbox>
                    <el-button @click="deLete(item, index, childIndex)">删除</el-button>
                  </el-form-item>
                  <el-button type="text" @click="addinputSingles(item,index)">添加选项</el-button>
                  <el-button type="text" @click="addSingleChoide()">添加试题</el-button>
                  <el-button type="text" @click="delSingleChoide(index)">删除试题</el-button>
                  <el-divider></el-divider>
                </el-col>
              </el-form>
            </div>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <div class="tip">判断题</div>
        <div class="content" v-for="(item,index) in judgment" :key="item.id">
          <el-row :gutter="100" >
            <el-form
              :model="item"
              label-position="right"
              label-width="120px"
              ref="classFrom" >
              <el-col :span="20">
                <el-form-item label="设置分数" required prop="">
                  <el-input style="width: 600px" v-model="item.score" type="number" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item :label="'题目'+(index+ 1)" required prop="">
                  <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"/>
                </el-form-item>
                <el-form-item v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                  <el-radio-group v-model="questionsOptions.correctAnswer">
                    <el-radio :label="1">正确</el-radio>
                    <el-radio :label="0">错误</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-button type="text" @click="addjudgment()">添加试题</el-button>
                <el-button type="text" @click="deljudgment(index)">添加试题</el-button>
              </el-col>
            </el-form>
          </el-row>
          <el-divider></el-divider>
        </div>
      </el-card>
      <el-card>
        <div class="tip">填空题</div>
        <div class="content" v-for="(item,index) in filling" :key="item.id">
          <el-row :gutter="100" >
            <el-form
              :model="item"
              label-position="right"
              label-width="120px"
              ref="classFrom" >
              <el-col :span="20">
                <el-form-item label="设置分数" required prop="">
                  <el-input style="width: 600px" v-model="item.score" type="number" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item :label="'题目'+(index+ 1)" required prop="">
                  <el-input :disabled="isDisabled" style="width: 600px" type="textarea" rows="7" v-model="item.question" @change="ChangeFilling(item,index)"/>
                </el-form-item>
                <el-form-item label="答案" required v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                  <el-input :disabled="isDisabled" style="width: 600px"  v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption" type="textarea" rows="4"/>
                </el-form-item>
                <el-button type="text" @click="addfilling()">添加试题</el-button>
                <el-button type="text" @click="delfilling(index)">添加试题</el-button>
              </el-col>
            </el-form>
          </el-row>
          <el-divider></el-divider>
        </div>
      </el-card>
      <el-button type="primary" style="margin-top: 12px;" @click="updataTestAndQuestionsInfo()">完成编辑</el-button>
    </el-row>
  </div>
</template>
<script>
  import { updataTestAndQuestionsInfo, detailTest } from '@/api/test.js'
  import { getUrl } from '@/utils/request.js'
  export default {
    name: 'Examination',
    data() {
      return {
        isDisabled: false,
        listone: [],
        radio: [],
        quesData: [{
          testId: '',
          questionsData: []
        }],
        url: getUrl() + '/bh/test/getQuestionsListToExcel',
        Examser: [{
          esesse: '',
          radio: ''
        }],
        testId: this.$route.query.id,
        list: [],
        judgment: [],
        short: [],
        filling: [],
        singleChoice: [],
        singleChoide: [],
        listTwo: [],
        active: 0,
        classFrom: {
          duration: '',
          value1: ''
        },
        radioTwo: 1
      }
    },
    methods: {
      not() {
        // const testStatus = row.testStatus
        // console.log(testStatus)
        // if (testStatus === 3) {}
        // if (this.data.testStatus === 3 || this.data.data.testStatus === 4) {
        //   console.log(this.data.testStatus)
        //   return true
        // }
      },
      detailTest() {
        if (this.testId) {
          detailTest(this.testId).then(resp => {
            console.log(resp.data.data.questionsData)
            const questionsData = resp.data.data.questionsData
            questionsData.forEach(item => {
              if (item.questionType === 1) {
                this.singleChoice.push(item)
              }
              if (item.questionType === 2) {
                this.singleChoide.push(item)
              }
              if (item.questionType === 4) {
                this.judgment.push(item)
              }
              if (item.questionType === 3) {
                this.filling.push(item)
              }
            })
            const status = resp.data.data.testStatus
            if (status === 3 || status === 4) {
               this.isDisabled = true
            }
            console.log(status)
          })
        }
      },
      handleAvatarSuccess (res, file) {
        console.log(res)
        this.$notify({
          title: '导入成功',
          type: 'success'
        })
      },
      answeiLable(index) {
        if (index === 0) {
          return 'A'
        } else if (index === 1) {
          return 'B'
        } else if (index === 2) {
          return 'C'
        } else if (index === 3) {
          return 'D'
        } else if (index === 4) {
          return 'E'
        } else if (index === 5) {
          return 'F'
        } else if (index === 6) {
          return 'G'
        }
      },
      delSingleChoice(index) {
        this.singleChoice.splice(index, 1)
      },
      delSingleChoide(index) {
        this.singleChoide.splice(index, 1)
      },
      deljudgment(index) {
        this.judgment.splice(index, 1)
      },
      delfilling(index) {
        this.filling.splice(index, 1)
      },
      ChangeFilling(item, row) {
        const question = item.question
        const questionList = []
        for (let i = 0; i < question.length; i++) {
          questionList.push(question.charAt(i))
        }
        let index = 0
        let def = true
        questionList.forEach(item => {
          if (item === '_') {
            if (def) {
              index++
              def = false
            }
          } else {
            def = true
          }
        })
        item.questionsOptions = []
        for (let i = 0; i < index; i++) {
          item.questionsOptions.push({ questionOption: '', correctAnswer: (i + 1) })
        }
        console.log(questionList)
        // this.filling.push(row, 1, item)
      },
      deLete(item, index, childrenIndex) { // 删除选项
        item.questionsOptions.splice(childrenIndex, 1)
        this.singleChoice.splice(index, 1, item)
      },
      poster() {
        this.$router.push({ path: '/Exercise' })
      },
      next() {
        if (this.active++ > 2) this.active = 0
      },
      // delSingleChoice(index, singleChoice) { // 删除单选试题
      //   console.log(index)
      //   index.singleChoice.splice(singleChoice, 1)
      //   this.singleChoide.splice({ answere: [] })
      // },
      addjudgment() { // 添加判断题试题
        this.judgment.push({ questionsOptions: [{ correctAnswer: '' }] })
      },
      addSingleChoice() { // 添加单选试题
        this.singleChoice.push({ questionsOptions: [] })
      },
      addSingleChoide() { // 添加多选试题
        this.singleChoide.push({ questionsOptions: [] })
      },
      addinputSingle(item, index) { // 单选选择题 添加选项
        console.log(item.questionsOptions)
        if (!item.questionsOptions) {
          console.log(123)
          item.questionsOptions = []
        }
        console.log(item)
        item.questionsOptions.push({ questionOption: '', correctAnswer: 0 })
        this.singleChoice.splice(index, 1, item)
      },
      addinputSingles(item, index) { // 多选择题 添加选项
        if (!item.questionsOptions) {
          console.log(123)
          item.questionsOptions = []
        }
        console.log(item)
        item.questionsOptions.push({ questionOption: '', correctAnswer: 0 })
        this.singleChoide.splice(index, 1, item)
      },
      addfilling() { // 添加填空题试题
        this.filling.push({ questionsOptions: [] })
      },
      addshort() { // 添加简答题
        this.short.push({ shortit: [] })
      },
      updataTestAndQuestionsInfo() {
        const questionsData = []
        this.singleChoice.forEach(item => {
          item.questionType = 1
          questionsData.push(item)
        })
        this.singleChoide.forEach(item => {
          item.questionType = 2
          questionsData.push(item)
        })
        this.filling.forEach(item => {
          item.questionType = 3
          questionsData.push(item)
        })
        this.judgment.forEach(item => {
          item.questionType = 4
          questionsData.push(item)
        })
        const quesData = {
          id: this.$route.query.id,
          questionsData: questionsData
        }
        console.log(quesData)
        updataTestAndQuestionsInfo(quesData).then(resp => {
          if (resp.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '提交成功。',
              type: 'success'
            })
          }
          this.$router.push({ path: '/Exercise' })
        })
      }
    },
    mounted() {
      this.detailTest() // 初始化考试信息
    }
  }
</script>

<style scoped>
  .body{padding: 20px 30px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);  background-color: #fff;}
  .content{margin-top: 25px;}
  .info-explain span{ margin-right: 25px;}
  .tip{text-align: left;padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;}
  .poster{position: absolute; left: 120px; top: 5px}
  .upload-btn{position: absolute; left: 20px; top: 5px}
</style>
